<template>
  <div class="section-top">
    <kinesis-container class="section-top__bg" :perspective="2000" :duration="1000">
      <kinesis-element :strength="100" type="translate" class="section-top__bg__paralax" tag="div">
        <img :src="webp(require(`../../../public/img/detail/${page}/${prefix}/bg-top-paralax.png`))" :alt="`${page} snack`">
      </kinesis-element>
      <div class="section-top__bg__logo">
        <img :src="webp(require(`../../../public/img/detail/${page}/${prefix}/bg-top-logo.png`))" :alt="`${page}`">
      </div>
      <div class="section-top__bg__product">
        <img class="lazyload pc-appear" :data-src="webp(require(`../../../public/img/detail/${page}/${prefix}/bg-top-product.png`))" :alt="`${page} snack`">
      </div>
    </kinesis-container>
  </div>
</template>

<script>
  import { KinesisContainer, KinesisElement } from 'vue-kinesis'

export default {
  name: 'Top',
  props: {
    page: String
  },
  components: {
    KinesisContainer,
    KinesisElement
  },
  data() {
    return {
      winWidth: 0,
      windowWidth: 0,
      isMobile: false,
      prefix: '',
    }
  },
  created() {
    this.getWindowWidth()
    this.checkDevice()
  },
  watch: {
    windowWidth: function() {
      this.checkDevice()
    }
  },
  mounted() {
    window.addEventListener('resize', this.getWindowWidth)
  },
  methods: {
    checkDevice() {
      if (this.windowWidth < 414) {
        this.isMobile = true
        this.prefix = 'sp'
      } else {
        this.prefix = 'pc'
      }
    },
    getWindowWidth() {
      this.windowWidth = window.outerWidth
    }
  }
}
</script>


<style lang="scss">
  @import '../../assets/scss/mixin/_mixin';

  .koimucho {
    .section-top {
      &__bg {
        background: url(../../../public/img/detail/koimucho/pc/bg-top.jpg) no-repeat top center / cover;

        @include media(set-min-w, 1100) {
          min-height: 650px;
        }
        @include media(set-min-w, 1280) {
          min-height: 700px;
        }
        @include media(set-min-w, 1366) {
          min-height: 750px;
        }
        @include media(set-min-w, 1440) {
          min-height: 800px;
        }
        @include media(set-min-w, 1580) {
          min-height: 850px;
        }
        @include media(set-min-w, 1700) {
          min-height: 950px;
        }
        @include media(set-max-w, 414) {
          background: url(../../../public/img/detail/koimucho/sp/bg-top.jpg) no-repeat top center / cover;
        }
        &__paralax {
          @include media(phone) {
            display: none;
          }
        }
        &__product {
          @include media(set-max-w, 767) {
            background-image: url(../../../public/img/detail/koimucho/sp/bg-top-product.png)
          }
        }
      }
    }
  }
  .karamucho {
    .section-top {
      &__bg {
        background: url(../../../public/img/detail/karamucho/pc/bg-top.jpg) no-repeat top center / cover;
        @include media(set-min-w, 980) {
          min-height: 650px;
        }
        @include media(set-min-w, 1280) {
          min-height: 700px;
        }
        @include media(set-min-w, 1366) {
          min-height: 750px;
        }
        @include media(set-min-w, 1440) {
          min-height: 800px;
        }
        @include media(set-min-w, 1580) {
          min-height: 850px;
        }
        @include media(set-min-w, 1800) {
          //min-height: 100vh;
        }
        @include media(set-max-w, 414) {
          background: url(../../../public/img/detail/karamucho/sp/bg-top.jpg) no-repeat top center / cover;
        }
        &__product {
          @include media(set-max-w, 767) {
            background-image: url(../../../public/img/detail/karamucho/sp/bg-top-product.png)
          }
        }
      }
    }
  }
  .section-top {
    position: relative;
    overflow: hidden;
    z-index: 0;

    &__bg {
      position: relative;
      height: 100vh;
      min-height: 700px;

      img {
        margin: 0 auto;
      }
      @include media(tablet) {
        min-height: 400px;
        max-height: 500px;
      }
      @include media(phone) {
        min-height: 500px;
        max-height: 760px
      }
      &__paralax {
        position: absolute;
        left: 0;
        top: -16%;
        width: 100%;
        z-index: 2;
        opacity: 0;
        animation: zoom 2s 1s ease-in-out forwards;
        
        @include media(tablet) {
          top: 4rem
        }
        @include media(phone) {
          top: 0
        }
      }
      &__logo {
        position: relative;
        text-align: center;
        padding-top: 3rem;
        z-index: 3;
        width: 23%;
        margin: 0 auto;

        // @include media(tablet) {
        //   padding: 18.5rem 10rem 0;
        // }
        @include media(phone) {
          padding: 8.5rem 3.5rem 0;
          width: 100%
        }
      }
      &__product {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 3;
        @include media(set-max-w, 767) {
          height: 44%;
          background: {
            size: contain;
            repeat: no-repeat;
            position: bottom center
          }
        }
      }
    }
  }
  @keyframes zoom {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .webp {
    &.koimucho {
      .section-top {
        &__bg {
          background: url(../../../public/img/detail/koimucho/pc/bg-top.webp) no-repeat top center / cover;
          @include media(set-max-w, 414) {
            background: url(../../../public/img/detail/koimucho/sp/bg-top.webp) no-repeat top center / cover
          }
        }
      }
    }
    &.karamucho {
      .section-top {
        &__bg {
          background: url(../../../public/img/detail/karamucho/pc/bg-top.webp) no-repeat top center / cover;
          @include media(set-max-w, 414) {
            background: url(../../../public/img/detail/karamucho/sp/bg-top.webp) no-repeat top center / cover;
          }
        }
      }
    }
  }
</style>
